import ItodoImage from "../../../elements/itodo-img";

function SectionOurInfo2() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-light sx-our-info-outer ">
                <div className="container">
                    <div className="section-content">
                        <div className="row sx-our-info-content-wrap">
                            {/*One block*/}
                            <div className="col-lg-4 col-md-4">
                                <div className="sx-our-info-content">
                                    <ul>
                                        <li>
                                            <div className="sx-our-info-content-list left">
                                                <span className="sx-sub-title">Working Experience</span>
                                                <h3 className="sx-bx-title">6+ Years Experience.</h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list left">
                                                <span className="sx-sub-title">Team</span>
                                                <h3 className="sx-bx-title">UX/UI Designers &amp; Frontend development</h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list left">
                                                <span className="sx-sub-title">Service</span>
                                                <h3 className="sx-bx-title">Web Template &amp; Apps Design.</h3>
                                            </div>
                                        </li>
                                        {/* 
                                        <li>
                                            <div className="sx-our-info-content-list left">
                                                <span className="sx-sub-title">Strategy</span>
                                                <h3 className="sx-bx-title">Brand Designer.</h3>
                                            </div>
                                        </li>
                                        */}
                                    </ul>
                                </div>
                            </div>
                            {/*One two*/}
                            <div className="col-lg-4 col-md-4">
                                <div className="sx-our-info-media-wrap">
                                    <div className="sx-our-info-media">
                                        <ItodoImage src="images/our-info.jpg" alt="image" />
                                    </div>
                                </div>
                            </div>
                            {/*One three*/}
                            <div className="col-lg-4 col-md-4">
                                <div className="sx-our-info-content">
                                    <ul>
                                        <li>
                                            <div className="sx-our-info-content-list right">
                                                <span className="sx-sub-title">Contact</span>
                                                <h3 className="sx-bx-title">info.@devnies.com
                                                    +974 7773 1706</h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list right">
                                                <span className="sx-sub-title">Location</span>
                                                <h3 className="sx-bx-title">Qatar, UAE, Oman, Saudi Arabia</h3>
                                            </div>
                                        </li>
                                        {/* 
                                        <li>
                                            <div className="sx-our-info-content-list right">
                                                <span className="sx-sub-title">Education</span>
                                                <h3 className="sx-bx-title">BSE in computer science.</h3>
                                            </div>
                                        </li>
                                        */}
                                        <li>
                                            <div className="sx-our-info-content-list right">
                                                <span className="sx-sub-title">Social Media</span>
                                                <h3 className="sx-bx-title">Facebook, Linkdin, Instagram, Snapchat.</h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SectionOurInfo2;